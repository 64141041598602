import { useQuery, useMutation } from '@tanstack/react-query'
import { API_PRODUCTS_URL } from '../../context/apiconfig'
import { productKeys } from './keyFactory'
import { useQueryClient } from '@tanstack/react-query'
import { IProductFilter } from './types'
import { api } from '../../helpers/Api'
import {
  IProductsDeal,
  IWarehouseLocationType,
} from '../../context/products/types'
import { IProductData } from '../../context/products/types'
import { ICouponCodesUsedData, ICouponCodesUsedFilter } from './types'
import { ISelectOption } from '../../types/interfaces'
import {
  UpdateProductImageOrderRequest,
  UpdateProductVideoOrderRequest,
  SendProductEditorEmailRequest,
  IProductEditorEmailData,
} from './types'

export function useGetProductEditorEmail(products_id: string | undefined, tab: string | undefined) {
  return useQuery<IProductEditorEmailData, Error>({
    queryKey: productKeys.productEditorEmail(products_id, tab),
    queryFn: async () => {
      const encodedProductId = encodeURIComponent(products_id ?? '');
      const encodedTab = encodeURIComponent(tab ?? '');
      const response = await api.get(`${API_PRODUCTS_URL}get-product-editor-email?products_id=${encodedProductId}&tab=${encodedTab}`);

      return response.data;
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  });
}

export function useProductList(filter: IProductFilter) {
  const queryClient = useQueryClient()

  return useQuery<IProductData[], Error>({
    queryKey: productKeys.productList(
      filter.products_status,
      filter.search_text,
      filter.categories_id,
      filter.brand_id,
      filter.suppliers_id,
      filter.is_on_wholesale_site,
      filter.is_on_proline_site,
      filter.group_by,
      filter.sort_by,
    ),
    queryFn: async () => {
      let response = await api.post(`${API_PRODUCTS_URL}list`, {
        ...filter,
      })

      return response.data
    },
  })
}

export function useSendProductEditorEmail() {
  return useMutation(
    (data: SendProductEditorEmailRequest) =>
      api.post(`${API_PRODUCTS_URL}send-product-editor-email`, data),
    {},
  )
}

export function useAddNewProduct() {
  return useMutation(
    (products_model: string) =>
      api.post(`${API_PRODUCTS_URL}add-new-product`, { products_model }),
    {},
  )
}
interface CloneProductParams {
  source_products_id: string
  target_products_model: string
}

export function useCloneProduct() {
  return useMutation(
    ({ source_products_id, target_products_model }: CloneProductParams) =>
      api.post(`${API_PRODUCTS_URL}clone-product`, {
        source_products_id,
        target_products_model,
      }),
    {},
  )
}

export function useDeleteProductVideo() {
  return useMutation(
    (videoId: string) =>
      api.delete(`${API_PRODUCTS_URL}delete-product-video?id=${videoId}`),
    {},
  )
}

export function useDeleteProductImage() {
  return useMutation(
    (imageId: string) =>
      api.delete(`${API_PRODUCTS_URL}delete-product-image?id=${imageId}`),
    {},
  )
}

export function useUpdateProductVideoOrder() {
  return useMutation<void, Error, UpdateProductVideoOrderRequest>(
    (images: UpdateProductVideoOrderRequest) => {
      return api.put(`${API_PRODUCTS_URL}update-product-video-order`, images)
    },
    {},
  )
}

export function useUpdateProductImageOrder() {
  return useMutation<void, Error, UpdateProductImageOrderRequest>(
    (images: UpdateProductImageOrderRequest) => {
      return api.put(`${API_PRODUCTS_URL}update-product-image-order`, images)
    },
    {},
  )
}

export function useCouponCodesUsed(filter: ICouponCodesUsedFilter) {
  return useQuery<ICouponCodesUsedData[], Error>({
    queryKey: productKeys.couponCodesUsedData(
      filter.staff_name,
      filter.start_date,
      filter.end_date,
      filter.code,
    ),
    queryFn: async () => {
      const response = await api.post(
        `${API_PRODUCTS_URL}get-coupon-codes-used`,
        { ...filter },
      )

      return response.data.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useProductsDeals() {
  return useQuery<IProductsDeal[], Error>({
    queryKey: productKeys.productsDealsListData(),
    queryFn: async () => {
      const response = await api.get(`${API_PRODUCTS_URL}deals`)

      return response.data.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useCagetoriesForFilter() {
  const queryClient = useQueryClient()

  return useQuery<ISelectOption[], Error>({
    queryKey: productKeys.categoriesForFilter(),
    queryFn: async () => {
      let response = await api.get(`${API_PRODUCTS_URL}get-filter-categories`)

      return response.data
    },
  })
}

export function useBrandsForFilter() {
  return useQuery<ISelectOption[], Error>({
    queryKey: productKeys.brandsForFilter(),
    queryFn: async () => {
      let response = await api.get(`${API_PRODUCTS_URL}get-filter-brands`)

      return response.data
    },
  })
}

export function useSuppliersForFilter() {
  return useQuery<ISelectOption[], Error>({
    queryKey: productKeys.suppliersForFilter(),
    queryFn: async () => {
      let response = await api.get(`${API_PRODUCTS_URL}get-filter-suppliers`)

      return response.data
    },
  })
}

export function useWarehouseStorageLocationTypes() {
  return useQuery<IWarehouseLocationType[], Error>({
    queryKey: productKeys.warehouseStorageLocationTypes(),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRODUCTS_URL}warehouse-location-types`,
      )

      return response.data
    },
  })
}
