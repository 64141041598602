import React from 'react'

const NextTab = () => {
  return (
    <div>
      Wake up Neo...
      <br />
      The Matrix has you... <br />
      Follow the white rabbit. <br />
      Knock, knock, Neo.
    </div>
  )
}

export default NextTab
