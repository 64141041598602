import {
  GET_CUSTOM_ASSORTMENTS,
  GET_ASSORTMENT_COMPONENTS,
  SEARCH_PRODUCTS_FOR_ASSORTMENTS,
  GET_ASSORTMENT_DETAILS,
  GET_ASSORTMENT_GHOST_ORDER,
  GET_DEAL_WITH_PRODUCTS,
  GET_ACTIVE_PRICE_LEVELS,
  GET_PRODUCT,
  GET_PRODUCT_COLORS,
  GET_PRODUCT_EFFECTS,
  GET_PRODUCT_FIRING_PATTERNS,
  GET_PRODUCT_DEBRIS_PROFILES,
  GET_PRODUCT_BRANDS,
  GET_PRODUCT_SUPPLIERS,
  GET_PRODUCT_CATEGORIES,
} from './constants'
import { initialState } from './ProductProvider'
import { IProductAction, IProductState } from './types'

export default function productReducer(
  state: IProductState = initialState,
  action: IProductAction,
): IProductState {
  const payload = action.payload

  switch (action.type) {
    case GET_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: payload,
      }

    case GET_PRODUCT_SUPPLIERS:
      return {
        ...state,
        productSuppliers: payload,
      }

    case GET_PRODUCT_BRANDS:
      return {
        ...state,
        productBrands: payload,
      }

    case GET_CUSTOM_ASSORTMENTS:
      return {
        ...state,
        customAssortmentsList: payload,
      }

    case GET_ASSORTMENT_COMPONENTS:
      return {
        ...state,
        componentsForAssortment: payload,
      }

    case SEARCH_PRODUCTS_FOR_ASSORTMENTS:
      return {
        ...state,
        assortmentProductsSearchResults: payload,
      }

    case GET_ASSORTMENT_DETAILS:
      return {
        ...state,
        customAssortmentDetails: payload,
      }

    case GET_ASSORTMENT_GHOST_ORDER:
      return {
        ...state,
        customAssortmentGhostOrder: payload,
      }

    case GET_DEAL_WITH_PRODUCTS:
      return {
        ...state,
        productsDeal: payload.deal,
        dealProducts: payload.products,
      }

    case GET_ACTIVE_PRICE_LEVELS:
      return {
        ...state,
        activePriceLevels: payload,
      }
    case GET_PRODUCT:
      return {
        ...state,
        product: payload,
      }
    case GET_PRODUCT_COLORS:
      return {
        ...state,
        productColors: payload,
      }

    case GET_PRODUCT_EFFECTS:
      return {
        ...state,
        productEffects: payload,
      }

    case GET_PRODUCT_FIRING_PATTERNS:
      return {
        ...state,
        productFiringPatterns: payload,
      }

    case GET_PRODUCT_DEBRIS_PROFILES:
      return {
        ...state,
        productDebrisProfiles: payload,
      }

    default:
      return state
  }
}
