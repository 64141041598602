import { useQuery, useMutation } from '@tanstack/react-query'
import { API_PRICING_URL } from '../../context/apiconfig'
import {
  pricingProductsMultiplierSettingsKeys,
  pricingForexListKeys,
  pricingInboundTemplateItemsListKeys,
  pricingInboundTemplateItemsContainerChargesKeys,
  inboundFreightKeys,
  manufacturerKeys,
  priceSuppliersKeys,
  filterKeys,
  pricingKeys,
} from './keyFactory'
import { useQueryClient } from '@tanstack/react-query'
import { ISelectOption } from '../../types/interfaces'
import { api } from '../../helpers/Api'
import {
  IPriceManufacturersData,
  IPriceManufacturerData,
  IManufacturersFilters,
  IPricingProductMultiplierSettingsData,
  IPricingForexDetailData,
  IPricingInboundTemplateItemsData,
  IPricingInboundTemplateItemsContainer,
  IPricingInboundTemplateItemsCharge,
  IPricingInboundTemplateItemsContainerChargesData,
  IUpdateManufacturerRequest,
  ICreateManufacturerRequest,
  IUpdateManufacturerResponse,
  IPricingVersionData,
  IPriceInboundFreightList,
  IPriceInboundFreightSearchParams,
  IPriceSuppliersSearchParams,
  IPriceSuppliersList,
  IVersionFilters,
  IBasketOfGoodsData,
  IReadyToActivateData,
  IUiLockStatusData,
  IUiLockStatus,
  ICategoryMultipliersData,
  UpdateLockStatusRequest,
  ISupplierManufacturerDiscountData,
  ISupplierManufacturerDiscountFilters,
  ICreateSupplierManufacturerDiscountRequest,
  IGetOneSupplierManufacturerDiscountRequest,
  IGetOneCategoryMultiplierRequest,
  IUpdateSupplierManufacturerDiscountRequest,
  ICategoryMultipliersFilters,
  IUpdateCategoryMultiplierRequest,
  ICreateCategoryMultiplierRequest,
} from './types'

const PRICING_PRODUCT_MULTIPLIER_SETTINGS_API_URL =
  'price-product-multiplier/price-product-multiplier-settings'
const PRICING_PRODUCTS_MULTIPLIER_SETTINGS_API_URL =
  'price-product-multiplier/price-products-multiplier-settings/read'

const PRICING_FOREX_LIST_API_URL = 'price-forex/list'
const PRICING_FOREX_DETAIL_API_URL = 'price-forex/detail/read'

const PRICING_INBOUND_TEMPLATE_ITEMS_LIST_API_URL =
  'price-inbound-template-items/list'

const PRICING_INBOUND_TEMPLATE_ITEMS_CONTAINER_CHARGES_API_URL =
  'price-inbound-template-items/container-charges/read'

export const usePriceSuppliersList = (
  searchParams: IPriceSuppliersSearchParams,
) => {
  return useQuery<IPriceSuppliersList, Error>({
    queryKey: priceSuppliersKeys.priceSuppliersList(
      searchParams.version_id,
      searchParams.search,
      searchParams.price_forex_description,
    ),
    queryFn: async () => {
      const searchArr = Object.entries(searchParams).filter(
        ([key, value]) => value != null,
      )
      const queryString = new URLSearchParams(searchArr).toString()

      const response = await api.get(
        `${API_PRICING_URL}price-suppliers?${queryString}`,
      )
      return response.data
    },
  })
}

export const useCurrencyTypesForFilter = () => {
  return useQuery<ISelectOption[], Error>({
    queryKey: filterKeys.currencyTypesForFilter(),
    queryFn: async () => {
      const response = await api.get(`${API_PRICING_URL}currency-type-filter`)
      return response.data
    },
  })
}

export const useInboundContainerOptions = () => {
  return useQuery<ISelectOption[], Error>({
    queryKey: filterKeys.inboundContainerOptions(),
    queryFn: async () => {
      const response = await api.get(
        `${API_PRICING_URL}inbound-container-options`,
      )
      return response.data
    },
  })
}

export const useUnusedSuppliers = () => {
  return useQuery<ISelectOption[], Error>({
    queryKey: priceSuppliersKeys.unusedSuppliers(),
    queryFn: async () => {
      const response = await api.get(`${API_PRICING_URL}unused-suppliers`)
      return response.data
    },
  })
}

export function useGetCategoryMultiplierList(
  version_id: string | undefined,
  filters: ICategoryMultipliersFilters,
) {
  return useQuery<ICategoryMultipliersData, Error>({
    queryKey: pricingKeys.categoryMultiplierList(
      version_id,
      filters.pricing_status,
      filters.categories_id,
    ),
    queryFn: async () => {
      let response = await api.post(
        `${API_PRICING_URL}get-category-multipliers`,
        {
          version_id: version_id,
          filters: filters,
        },
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useReadManufacturers(
  version_id: string | undefined,
  filters: IManufacturersFilters,
) {
  return useQuery<IPriceManufacturersData, Error>({
    queryKey: pricingKeys.manufacturerList(
      version_id,
      filters.pricing_status,
      filters.manufacturers_status,
    ),
    queryFn: async () => {
      let response = await api.post(`${API_PRICING_URL}manufacturers`, {
        version_id: version_id,
        filters: filters,
      })
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useCreateSupplierManufacturerDiscount() {
  return useMutation<void, Error, ICreateSupplierManufacturerDiscountRequest>(
    (discount: ICreateSupplierManufacturerDiscountRequest) => {
      return api.post(
        `${API_PRICING_URL}create-supplier-manufacturer-discount`,
        discount,
      )
    },
    {},
  )
}

export function useUpdateSupplierManufacturerDiscount() {
  return useMutation<void, Error, IUpdateSupplierManufacturerDiscountRequest>(
    (discount: IUpdateSupplierManufacturerDiscountRequest) => {
      return api.put(
        `${API_PRICING_URL}supplier-manufacturer-discount`,
        discount,
      )
    },
    {},
  )
}

export function useUpdateCategoryMultiplier() {
  return useMutation<void, Error, IUpdateCategoryMultiplierRequest>(
    (multiplier: IUpdateCategoryMultiplierRequest) => {
      return api.put(`${API_PRICING_URL}category-multiplier`, multiplier)
    },
    {},
  )
}

export function useCreateManufacturer() {
  return useMutation<void, Error, ICreateManufacturerRequest>(
    (manufacturer: ICreateManufacturerRequest) => {
      return api.post(`${API_PRICING_URL}manufacturer`, manufacturer)
    },
    {},
  )
}

export function useGetOneCategoryMultiplier(id: string | undefined) {
  return useQuery<IGetOneCategoryMultiplierRequest, Error>({
    queryKey: pricingKeys.categoryMultiplierDetail(id),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRICING_URL}category-multiplier/${id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useCreateCategoryMultiplier() {
  return useMutation<void, Error, ICreateCategoryMultiplierRequest>(
    (multiplier: ICreateCategoryMultiplierRequest) => {
      return api.post(
        `${API_PRICING_URL}create-category-multiplier`,
        multiplier,
      )
    },
    {},
  )
}

export function useUpdateLockStatus() {
  return useMutation<void, Error, UpdateLockStatusRequest>(
    (data: UpdateLockStatusRequest) => {
      return api.put(`${API_PRICING_URL}update_lock_status`, data)
    },
  )
}

export function useAddNewVersion() {
  return useMutation(
    () => api.post(`${API_PRICING_URL}add-new-version`, {}),
    {},
  )
}

export function useUpdateManufacturer() {
  return useMutation<
    IUpdateManufacturerResponse,
    Error,
    IUpdateManufacturerRequest
  >((manufacturer: IUpdateManufacturerRequest) => {
    return api
      .put(`${API_PRICING_URL}manufacturer`, manufacturer)
      .then((response) => response.data)
  }, {})
}

export function useGetVersions(filters: IVersionFilters) {
  return useQuery<Array<IPricingVersionData>, Error>(
    pricingKeys.versions(
      filters.status,
      filters.search_text,
      filters.start_date,
      filters.end_date,
    ),
    async () => {
      const response = await api.post(`${API_PRICING_URL}versions`, {
        filters: filters,
      })
      return response.data
    },
    {
      keepPreviousData: true,
      staleTime: 3 * 1000,
    },
  )
}

export function useGetBasketOfGoods(version_id: string | undefined) {
  return useQuery<IBasketOfGoodsData, Error>({
    queryKey: pricingKeys.basketOfGoods(version_id),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRICING_URL}basket-of-goods/${version_id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useGetOneSupplierManufacturerDiscount(id: string | undefined) {
  return useQuery<IGetOneSupplierManufacturerDiscountRequest, Error>({
    queryKey: pricingKeys.supplierManufacturerDiscountDetail(id),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRICING_URL}supplier-manufacturer-discount/${id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useGetSupplierManufacturerDiscount(
  version_id: string | undefined,
  filters: ISupplierManufacturerDiscountFilters,
) {
  return useQuery<ISupplierManufacturerDiscountData, Error>({
    queryKey: pricingKeys.supplierManufacturerDiscount(
      version_id,
      filters.suppliers_id,
      filters.manufacturers_id,
    ),
    queryFn: async () => {
      let response = await api.post(
        `${API_PRICING_URL}supplier-manufacturer-discount`,
        {
          version_id: version_id,
          filters: filters,
        },
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useGetOneManufacturer(id: string | undefined) {
  return useQuery<IPriceManufacturerData, Error>({
    queryKey: pricingKeys.manufacturerDetail(id),
    queryFn: async () => {
      let response = await api.get(`${API_PRICING_URL}manufacturer/${id}`)
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function usePricingProductMultiplierSettingsList(
  version_id: string | undefined,
) {
  const queryClient = useQueryClient()

  return useQuery<IPricingProductMultiplierSettingsData, Error>({
    queryKey:
      pricingProductsMultiplierSettingsKeys.pricingProductsMultiplierSettingsList(
        version_id,
      ),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRICING_URL}${PRICING_PRODUCTS_MULTIPLIER_SETTINGS_API_URL}/${version_id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function usePricingProductsMultiplierSettingsDetail(
  id: string | undefined,
) {
  return useQuery<IPricingProductMultiplierSettingsData, Error>({
    queryKey:
      pricingProductsMultiplierSettingsKeys.pricingProductsMultiplierSettings(
        id,
      ),
    queryFn: async () => {
      const response = await api.get(
        `${API_PRICING_URL}${PRICING_PRODUCT_MULTIPLIER_SETTINGS_API_URL}/${id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3,
  })
}

//TODO REMOVE BEGIN RICKS CODE

export function usePricingForexList(version_id: string | undefined) {
  const queryClient = useQueryClient()

  return useQuery<IPricingForexDetailData, Error>({
    queryKey: pricingForexListKeys.pricingForexList(version_id),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRICING_URL}${PRICING_FOREX_LIST_API_URL}/${version_id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function usePricingForexDetail(id: string | undefined) {
  return useQuery<IPricingForexDetailData, Error>({
    queryKey: pricingForexListKeys.pricingForex(id),
    queryFn: async () => {
      const response = await api.get(
        `${API_PRICING_URL}${PRICING_FOREX_DETAIL_API_URL}/${id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3,
  })
}

export function usePricingInboundTemplateItemsList(
  version_id: string | undefined,
) {
  const queryClient = useQueryClient()

  return useQuery<IPricingInboundTemplateItemsData, Error>({
    queryKey:
      pricingInboundTemplateItemsListKeys.pricingInboundTemplateItemsList(),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRICING_URL}${PRICING_INBOUND_TEMPLATE_ITEMS_LIST_API_URL}/${version_id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function usePricingInboundTemplateItemsContainerChargesData(
  container_id: string | undefined,
  version_id: string | undefined,
) {
  // Construct the URL with the parameters
  const url = `${API_PRICING_URL}${PRICING_INBOUND_TEMPLATE_ITEMS_CONTAINER_CHARGES_API_URL}?container_id=${container_id}&version_id=${version_id}`

  return useQuery<IPricingInboundTemplateItemsContainerChargesData, Error>({
    queryKey:
      pricingInboundTemplateItemsContainerChargesKeys.pricingInboundTemplateItemsContainerCharges(
        container_id,
        version_id,
      ),
    queryFn: async () => {
      const response = await api.get(url)
      return response.data
    },
    staleTime: 3,
  })
}

//TODO REMOVE END RICKS CODE

export function useGetVersionReadyToActivate(version_id: string | undefined) {
  return useQuery<IReadyToActivateData, Error>({
    queryKey: pricingKeys.readyToActivate(version_id),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRICING_URL}ready_to_activate/${version_id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useGetOneUiLockStatus(
  version_id: string | undefined,
  price_ui_id: string | undefined,
) {
  return useQuery<IUiLockStatus, Error>({
    queryKey: pricingKeys.oneUiLockStatus(version_id, price_ui_id),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRICING_URL}lock_status/get-one/${version_id}?price_ui_id=${price_ui_id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export function useGetUiLockStatus(version_id: string | undefined) {
  return useQuery<IUiLockStatusData, Error>({
    queryKey: pricingKeys.uiLockStatus(version_id),
    queryFn: async () => {
      let response = await api.get(
        `${API_PRICING_URL}lock_status/${version_id}`,
      )
      return response.data
    },
    keepPreviousData: true,
    staleTime: 3 * 1000,
  })
}

export const usePriceInboundFreightList = (
  searchParams: IPriceInboundFreightSearchParams,
) => {
  return useQuery<IPriceInboundFreightList, Error>({
    queryKey: inboundFreightKeys.priceFreightInbound(),
    queryFn: async () => {
      const searchArr = Object.entries(searchParams).filter(
        ([key, value]) => value != null,
      )

      const queryString = new URLSearchParams(searchArr).toString()
      const response = await api.get(
        `${API_PRICING_URL}price-inbound-freight?${queryString}`,
      )
      return response.data
    },
  })
}

export const useInboundFreightTypesForFilter = () => {
  return useQuery<ISelectOption[], Error>({
    queryKey: filterKeys.inboundFreightTypesForFilter(),
    queryFn: async () => {
      const response = await api.get(
        `${API_PRICING_URL}price-inbound-freight/type-filter`,
      )
      return response.data
    },
  })
}
