import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import RwCard from '../../../../molecules/RwCard'
import { Card, Row, Col } from 'react-bootstrap'
import RwDisplayField from '../../../../molecules/RwDisplayField'
import RwNavBar from '../../../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../../../molecules/RwNav/RwNavItem'
import { usePricingContext } from '../../../../../context/pricing/PricingProvider'

export const BasketOfGoodsDetailBreadcrumb: React.FC = () => {
  const { basketOfGoodsItem } = usePricingContext()

  return (
    <span>
      {basketOfGoodsItem?.products_model
        ? `${basketOfGoodsItem.products_model} - ${
            basketOfGoodsItem.products_name || 'Unnamed Product'
          }`
        : 'Loading...'}
    </span>
  )
}

function Detail() {
  const { basket_of_goods_id, version_id } = useParams()
  const navigate = useNavigate()

  const handleClose = () => {
    navigate(`/accounting/product-pricing-projection-versions/${version_id}`)
  }

  const { basketOfGoodsItem, getBasketOfGoodsItem } = usePricingContext()

  useEffect(() => {
    if (basket_of_goods_id) {
      getBasketOfGoodsItem(basket_of_goods_id)
    }
  }, [basket_of_goods_id])

  if (!basketOfGoodsItem) {
    return <></>
  }

  return (
    <div>
      <RwNavBar>
        <RwNavItem variant="close" onClick={handleClose} />{' '}
      </RwNavBar>
      <RwCard className={'m-3'}>
        <Card.Header className="fw-bold">Basket of Goods Detail</Card.Header>
        <Card.Body className="p-2">
          <Row>
            <Col md={4}>
              <RwDisplayField
                value={basketOfGoodsItem.products_id}
                label="Products ID"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={basketOfGoodsItem.products_model}
                label="Products Model"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={basketOfGoodsItem.products_name}
                label="Products Name"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <RwDisplayField
                value={basketOfGoodsItem.version_id}
                label="Version ID"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={basketOfGoodsItem.manufacturers_name}
                label="Manufacturer"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={basketOfGoodsItem.suppliers_name}
                label="Supplier"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <RwDisplayField
                value={basketOfGoodsItem.products_fob}
                label="FOB Cost"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={basketOfGoodsItem.base_price}
                label="Base Price"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField value="" label="Suggested Base Price" />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <RwDisplayField value="" label="Margin" />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value=""
                label="Product CBM"
              />
            </Col>
            <Col md={4}>
              <RwDisplayField
                value={basketOfGoodsItem.estimated_landed_cost}
                label="Estimated Landed Cost"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="centered-content">
                <span>
                  Mimic the formula so the users can see how the suggested price
                  was calculated. (TBD)
                </span>
                <div style={{ height: '200px' }}></div>
              </div>
            </Col>
            <Col md={6}>
              <div className="centered-content">
                <span>Net realization Average (TBD)</span>
                <div style={{ height: '200px' }}></div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </RwCard>
    </div>
  )
}

export default Detail
