import React, { useEffect, useState } from 'react'
import { Card, Form, Container, Row, Col } from 'react-bootstrap'
import RwFormikInput from '../../molecules/RwFormikInput'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { determineChanges } from '../../../helpers'
import RwCard from '../../molecules/RwCard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RwSubmitButton from '../../molecules/RwButton/RwSubmitButton'
import RwCloseButton from '../../molecules/RwButton/RwCloseButton'
import RwButtonGroup from '../../molecules/RwButton/RwButtonGroup'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import ModalConfirmation from '../../organisms/Common/Modal/ModalConfirmation'
import RwDisplayField from '../../molecules/RwDisplayField'
import AuditLog from '../../organisms/AuditLog'
import { useIamContext } from '../../../context/iam/IamProvider'
import { IPermissionData, IRoleData } from '../../../context/iam/types'
import RwTable from '../../molecules/RwTable'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import { BsPencilSquare } from 'react-icons/bs'

export const RoleBreadcrumb: React.FC = () => {
  const { role } = useIamContext()
  return <span>{role?.role_name ? role?.role_name : 'New Role'}</span>
}

const Role: React.FC = () => {
  const { id } = useParams()
  const isNew = id === 'new'

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  const [onSaveClose, setOnSaveClose] = useState<boolean>(false)
  const [selectedRole, setSelectedRole] = useState<IRoleData | null>(null)

  const navigate = useNavigate()
  const location = useLocation()

  const { role, getRole, setRole, updateRole, deleteRole, clearRoleContext } =
    useIamContext()

  useEffect(() => {
    return () => {
      clearRoleContext()
    }
  }, [])

  useEffect(() => {
    if (!id) {
      return
    }

    if (isNew) {
      const elems = document.getElementsByName(
        'role_name',
      ) as NodeListOf<HTMLInputElement>
      if (elems) {
        elems.forEach((elem: HTMLInputElement) => {
          elem.focus()
        })
      }
    } else {
      getRole(id)
    }
  }, [id])

  useEffect(() => {
    if (role?.id) {
      setSelectedRole(role)
    }
  }, [role?.id])

  useEffect(() => {
    if (isNew && role?.id) {
      navigate(location.pathname.replace(`/new`, `/${role.id}`))
    }
  }, [role?.id])

  if ((!id || !role) && !isNew) {
    return <></>
  }

  const validationSchema = Yup.object().shape(
    {
      role_name: Yup.string().required('Required'),
      description: Yup.string(),
    },
    [],
  )

  const initialValues = {
    id: role?.id || '',
    role_name: role?.role_name || '',
    description: role?.description || '',
  }

  const onSubmit = (values: any, submitProps: FormikHelpers<any>) => {
    const updatedRoleInfo = role
      ? determineChanges(initialValues, values, ['id'], isNew)
      : { ...values, id: null }

    updateRole(updatedRoleInfo, handleOnSaveClose)
  }

  const handleOnSaveClose = () => {
    if (onSaveClose) {
      handleClose()
    }
  }

  const handleClose = () => {
    navigate(-1)
  }

  const handleDeleteClick = () => {
    setShowConfirmDelete(true)
  }

  const handleDeleteConfirm = () => {
    deleteRole(id, handleClose)
  }

  const handleManagePermissions = () => {
    navigate(`${location.pathname}/permissions`)
  }
  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          isValid,
          dirty,
          setFieldValue,
          resetForm,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <RwNavBar>
              <RwNavItem variant="close" onClick={handleClose} />
              <RwNavItem variant="remove" onClick={handleDeleteClick} />
              <RwNavItem
                variant="key"
                title="Manage Permissions"
                onClick={handleManagePermissions}
              />

              <RwNavItem
                fill
                variant="revert"
                disabled={!dirty}
                onClick={() => {
                  resetForm()
                  setRole(selectedRole)
                }}
              />
              <RwNavItem
                variant="save"
                disabled={!dirty || !isValid}
                onClick={() => {
                  if (dirty && isValid) {
                    handleSubmit()
                  }
                }}
              />
              <RwNavItem
                variant="save"
                title="Save & Close"
                disabled={!dirty || !isValid}
                onClick={() => {
                  if (dirty && isValid) {
                    setOnSaveClose(true)
                    handleSubmit()
                  }
                }}
              />
            </RwNavBar>
            <div className="p-4">
              <Container className="m-0 p-0" fluid>
                <Row>
                  <Col md={12} lg={5}>
                    <RwCard className="">
                      <Card.Header className="fw-bold">
                        <div className="d-flex">
                          <div>Role Details</div>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-2">
                        <RwFormikInput name="role_name" label="Role Name" />
                        <RwFormikInput
                          as="textarea"
                          name="description"
                          label="Description"
                          height="120px"
                        />
                      </Card.Body>
                    </RwCard>
                  </Col>
                  <Col md={12} lg={4}>
                    <RwCard className="">
                      <Card.Header className="fw-bold">
                        <div className="d-flex">
                          <div>Permissions</div>
                          <div
                            className="ms-2 cursor-pointer"
                            onClick={handleManagePermissions}
                          >
                            <BsPencilSquare />
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-2">
                        {role?.permissions && (
                          <RwTable>
                            {role?.permissions?.map(
                              (permission: IPermissionData) => {
                                return (
                                  <>
                                    <RwTableBody>
                                      <RwTableRow>
                                        <RwTableCell className="p-1">
                                          {permission.permission_name}
                                        </RwTableCell>
                                      </RwTableRow>
                                    </RwTableBody>
                                  </>
                                )
                              },
                            )}
                          </RwTable>
                        )}
                      </Card.Body>
                    </RwCard>
                  </Col>
                  <Col md={12} lg={3}>
                    <RwCard>
                      <Card.Header className="fw-bold">
                        <div className="d-flex">
                          <div>Role Metadata</div>
                          <AuditLog table="roles" keyValue={id} fieldMap={{}} />
                        </div>
                      </Card.Header>
                      <Card.Body className="p-2">
                        <RwDisplayField
                          value={role?.created_at?.toString()}
                          label="Created Date"
                        />
                        <RwDisplayField
                          value={role?.updated_at?.toString()}
                          label="Updated Date"
                        />
                      </Card.Body>
                    </RwCard>
                  </Col>
                </Row>
              </Container>

              <RwButtonGroup>
                <RwSubmitButton
                  onClick={() => {
                    setOnSaveClose(true)
                  }}
                  disabled={!dirty || !isValid}
                >
                  Save & Close
                </RwSubmitButton>
                <RwSubmitButton disabled={!dirty || !isValid}>
                  Save
                </RwSubmitButton>
                <RwCloseButton onClick={handleClose} />
              </RwButtonGroup>
            </div>
          </Form>
        )}
      </Formik>
      <ModalConfirmation
        showModal={showConfirmDelete}
        setShowModal={setShowConfirmDelete}
        handleConfirm={handleDeleteConfirm}
        title="Delete Role"
        confirmButtonText="Delete"
        body={<span className="fs-5">Are you sure you want to delete?</span>}
      />
    </div>
  )
}

export default Role
