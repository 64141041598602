export const priceSuppliersKeys = {
  all: ['priceSuppliers'] as const,
  priceSuppliersList: (
    version_id: string | null | undefined,
    search: string | null | undefined,
    price_forex_id: string | null | undefined,
  ) => [...priceSuppliersKeys.all, version_id, search, price_forex_id] as const,
  priceSuppliersDetail: (id: string | undefined) =>
    [...priceSuppliersKeys.all, id] as const,
  unusedSuppliers: () =>
    [...priceSuppliersKeys.all, 'unusedSuppliers'] as const,
}

export const pricingProductsMultiplierSettingsKeys = {
  all: ['pricingProductMultiplierSettingsList'] as const,
  pricingProductsMultiplierSettingsList: (version_id: string | undefined) =>
    [...pricingProductsMultiplierSettingsKeys.all, version_id] as const,
  pricingProductsMultiplierSettings: (id: string | undefined) =>
    [...pricingProductsMultiplierSettingsKeys.all, id] as const,
}

export const pricingKeys = {
  all: ['manufacturerList'] as const,
  manufacturerList: (
    version_id: string | undefined,
    pricing_status: string | undefined,
    manufacturers_status: string | undefined,
  ) =>
    [
      ...pricingKeys.all,
      pricing_status,
      manufacturers_status,
      version_id,
    ] as const,
  manufacturerDetail: (id: string | undefined) =>
    [...pricingKeys.all, id, 'manufacturerDetail'] as const,
  supplierManufacturerDiscountDetail: (id: string | undefined) =>
    [...pricingKeys.all, id, 'supplierManufacturerDiscountDetail'] as const,
  version: (id: string) => [...pricingKeys.all, id] as const,
  versions: (
    status: string | undefined,
    search_text: string | undefined,
    start_date: Date | null | undefined,
    end_date: Date | null | undefined,
  ) => [...pricingKeys.all, status, search_text, start_date, end_date] as const,
  categoryMultiplierDetail: (id: string | undefined) =>
    [...pricingKeys.all, id, 'categoryMultiplierDetail'] as const,
  categoryMultiplierList: (
    version_id: string | undefined,
    pricing_status: string | undefined,
    categories_id: string | undefined,
  ) =>
    [
      ...pricingKeys.all,
      'basketOfGoods',
      version_id,
      pricing_status,
      categories_id,
    ] as const,

  basketOfGoods: (version_id: string | undefined) =>
    [...pricingKeys.all, 'basketOfGoods', version_id] as const,
  uiLockStatus: (version_id: string | undefined) =>
    [...pricingKeys.all, 'uiLockStatus', version_id] as const,
  supplierManufacturerDiscount: (
    version_id: string | undefined,
    suppliers_id: string | undefined,
    manufacturers_id: string | undefined,
  ) =>
    [
      ...pricingKeys.all,
      'supplierManufacturerDiscount',
      version_id,
      suppliers_id,
      manufacturers_id,
    ] as const,
  oneUiLockStatus: (
    version_id: string | undefined,
    price_ui_id: string | undefined,
  ) => [...pricingKeys.all, 'uiLockStatus', version_id, price_ui_id] as const,
  readyToActivate: (version_id: string | undefined) =>
    [...pricingKeys.all, 'readyToActivate', version_id] as const,
  basketOfGoodsItem: (basket_of_goods_id: string | undefined) =>
    [...pricingKeys.all, basket_of_goods_id] as const,
}
//TODO REMOVE BEGIN RICKS CODE
export const pricingForexListKeys = {
  all: ['pricingForexList'] as const,
  pricingForexList: (version_id: string | undefined) =>
    [...pricingForexListKeys.all, version_id] as const,
  pricingForex: (id: string | undefined) =>
    [...pricingForexListKeys.all, id] as const,
}

export const pricingInboundTemplateItemsListKeys = {
  all: ['pricingInboundTemplateItemsList'] as const,
  pricingInboundTemplateItemsList: () =>
    [...pricingInboundTemplateItemsListKeys.all] as const,
  pricingInboundTemplateItems: (id: string | undefined) =>
    [...pricingInboundTemplateItemsListKeys.all, id] as const,
}

export const pricingInboundTemplateItemsContainerChargesKeys = {
  all: ['pricingInboundTemplateItemsContainerCharges'] as const,
  pricingInboundTemplateItemsContainerCharges: (
    container_id: string | undefined, 
    version_id: string | undefined
  ) => [...pricingInboundTemplateItemsContainerChargesKeys.all, container_id, version_id] as const,
}
//TODO REMOVE END RICKS CODE

export const inboundFreightKeys = {
  all: ['priceFreightInbound'] as const,
  priceFreightInbound: () =>
    [...inboundFreightKeys.all, 'priceFreightInbound'] as const,
}

export const manufacturerKeys = {
  all: ['manufacturerList'] as const,
  manufacturerList: (
    pricing_status: string | undefined,
    manufacturers_status: string | undefined,
  ) => [...manufacturerKeys.all, pricing_status, manufacturers_status] as const,
  manufacturerDetail: (id: string | undefined) =>
    [...manufacturerKeys.all, id] as const,
}

export const filterKeys = {
  all: ['filters'] as const,
  inboundFreightTypesForFilter: () =>
    [...filterKeys.all, 'inboundFreightTypesForFilter'] as const,
  currencyTypesForFilter: () =>
    [...filterKeys.all, 'currencyTypesForFilter'] as const,
  forexOptionsForFilter: () =>
    [...filterKeys.all, 'forexOptionsForFilter'] as const,
  inboundContainerOptions: () =>
    [...filterKeys.all, 'inboundFreightOptions'] as const,
}
