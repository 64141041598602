import React, { useState } from 'react'
import { Form, Modal, Stack } from 'react-bootstrap'
import RwCloseButton from '../../../molecules/RwButton/RwCloseButton'
import * as Yup from 'yup'
import { Formik } from 'formik'
import RwSubmitButton from '../../../molecules/RwButton/RwSubmitButton'
import { passwordValidationSchema } from '../../../../constants/constants'
import PasswordHint from '../../../molecules/PasswordHint'
import { IChangePasswordData } from '../../../../context/user/types'
import RwFormikInputGroup from '../../../molecules/RwFormikInputGroup'
import EyeButton from '../../Login/EyeButton'

export interface IPasswordModal {
  show: boolean
  handleClose: () => void
  handleSave: (values: IChangePasswordData) => void
  [x: string]: any
}

const PasswordModal: React.FC<IPasswordModal> = ({
  show,
  handleClose,
  handleSave,
  ...rest
}) => {
  const [maskNewViaPasswordType, setMaskNewViaPasswordType] = useState(true)

  const validationSchema = Yup.object().shape(
    {
      new_password: passwordValidationSchema,
      new_password_confirmation: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('new_password'), null], 'passwords must match'),
    },
    [],
  )

  const initialValues = {
    new_password: '',
    new_password_confirmation: '',
  }

  const onSubmit = (values: any) => {
    handleSave({
      ...values,
    })
  }

  const handleEyeClickNew = () => {
    setMaskNewViaPasswordType(!maskNewViaPasswordType)
  }

  const handleCloseWindow = () => {
    handleClose()
  }

  return (
    <Modal className="rw-change-password-modal" show={show} animation={false}>
      <Modal.Header>
        <Modal.Title className="w-100">
          <div className="d-flex">
            <div>Change Password</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={initialValues}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            isValid,
            dirty,
            setFieldValue,
            resetForm,
            values,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="p-4">
                <RwFormikInputGroup
                  name="new_password"
                  label="New Password"
                  type={maskNewViaPasswordType ? 'password' : 'text'} // to enable custom eye/mask
                  autoComplete="off"
                  suffix={
                    <EyeButton
                      showPassword={maskNewViaPasswordType}
                      handleClick={handleEyeClickNew}
                    />
                  }
                  hint={
                    <PasswordHint passwordInputValue={values.new_password} />
                  }
                />
                <RwFormikInputGroup
                  name="new_password_confirmation"
                  label="Re-Enter New Password"
                  type={maskNewViaPasswordType ? 'password' : 'text'} // to enable custom eye/mask
                  autoComplete="off"
                  suffix={
                    <EyeButton
                      showPassword={maskNewViaPasswordType}
                      handleClick={handleEyeClickNew}
                    />
                  }
                />
              </div>
              <Stack direction="horizontal" gap={3}>
                <RwSubmitButton disabled={!dirty || !isValid}>
                  Save
                </RwSubmitButton>
                <RwCloseButton onClick={handleCloseWindow}>
                  Cancel
                </RwCloseButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default PasswordModal
