export const productKeys = {
  all: ['productList'] as const,
  productList: (
    products_status: string | undefined,
    search_text: string | undefined,
    categories_id: number | undefined,
    brand_id: number | undefined,
    supplier_id: number | undefined,
    is_on_wholesale_site: boolean | undefined,
    is_on_proline_site: boolean | undefined,
    group_by: string | undefined,
    sort_by: string | undefined,
  ) =>
    [
      ...productKeys.all,
      products_status,
      search_text,
      categories_id,
      brand_id,
      supplier_id,
      is_on_wholesale_site,
      is_on_proline_site,
      group_by,
      sort_by,
    ] as const,
  productEditorEmail: (
    products_id: string | undefined,
    tab: string | undefined,
  ) => [...productKeys.all, products_id, tab, 'productEditorEmail'] as const,
  couponCodesUsedData: (
    staff_name: string | undefined,
    start_date: Date | undefined,
    end_date: Date | undefined,
    code: string | undefined,
  ) => [...productKeys.all, staff_name, start_date, end_date, code] as const,
  productsDealsListData: () => [...productKeys.all] as const,
  categoriesForFilter: () =>
    [...productKeys.all, 'categoriesForFilter'] as const,
  brandsForFilter: () => [...productKeys.all, 'brandsForFilter'] as const,
  suppliersForFilter: () => [...productKeys.all, 'suppliersForFilter'] as const,
  warehouseStorageLocationTypes: () =>
    [...productKeys.all, 'warehouseStorageLocationTypes'] as const,
}
