import React, { JSXElementConstructor, ReactElement } from 'react'
import { ListGroup } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'
import {
  BsFillTrashFill,
  BsFillBanFill,
  BsCopy,
  BsPencilSquare,
  BsListTask,
  BsPinAngle,
} from 'react-icons/bs'
import { isAuthorized } from '../../../helpers/Authorization'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'
export interface IRowContextMenuItem {
  label?: string
  variant?:
    | 'remove'
    | 'deactivate'
    | 'edit'
    | 'clone'
    | 'tasks'
    | 'remove all'
    | 'pin'
  icon?: ReactElement<any, string | JSXElementConstructor<any>>
  disabled?: boolean
  className?: string
  handleClick: (data: unknown) => void
  permission?: string
  [x: string]: any
}

const RowContextMenuItem: React.FC<IRowContextMenuItem> = ({
  label = null,
  variant = null,
  icon = null,
  disabled = false,
  className = '',
  handleClick,
  permission = null,
  ...rest
}) => {
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const iconClass = 'my-auto me-2'
  let variantIcon = null
  let variantLabel = null

  switch (variant) {
    case 'remove':
      variantIcon = <BsFillTrashFill />
      variantLabel = 'Delete'
      break
    case 'remove all':
      variantIcon = <BsFillTrashFill />
      variantLabel = 'Delete All'
      break
    case 'edit':
      variantIcon = <BsPencilSquare />
      variantLabel = 'Edit'
      break
    case 'deactivate':
      variantIcon = <BsFillBanFill />
      variantLabel = 'Deactivate'
      break
    case 'clone':
      variantIcon = <BsCopy />
      variantLabel = 'Clone'
      break
    case 'tasks':
      variantIcon = <BsListTask />
      variantLabel = 'Tasks'
      break
    case 'pin':
      variantIcon = <BsPinAngle />
      variantLabel = 'Pin'
      break
  }

  const iconClone = icon ? (
    React.cloneElement(icon, {
      className: iconClass,
    })
  ) : variantIcon ? (
    React.cloneElement(variantIcon, {
      className: iconClass,
    })
  ) : (
    <></>
  )
  const hasAccess = !permission || isAuthorized(user, [permission])

  return (
    <ListGroup.Item
      action
      onClick={handleClick}
      disabled={!hasAccess || disabled}
      {...rest}
    >
      {iconClone} {label ? label : variantLabel ? variantLabel : null}
    </ListGroup.Item>
  )
}

export default RowContextMenuItem
